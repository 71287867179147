.identity {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	text-align: center;
	text-transform: uppercase;
}

.identity > h1 {
	font-weight: 700;
	margin: 0;
}

.identity > h2 {
	font-weight: 100;
	font-size: 1.1em;
	letter-spacing: 3px;
	margin: 0;
}

.identity > img {
	border-radius: 50%;
	border-color:#fff;
	border: 2px solid;
	max-width: 50vw;
	width: 250px;
	margin: 5px 0;
}