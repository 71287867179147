.App {
	text-align: center;
	font-family: Lato, sans-serif;
}

.App-header {
  background-image: url(/static/media/bg-min.17511834.jpg);
	background-size: cover;
	background-position-x: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.overlay {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	width: 100vw;
	height: 100vh;
}

.App-link {
  color: #61dafb;
}
.identity {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 100%;
	text-align: center;
	text-transform: uppercase;
}

.identity > h1 {
	font-weight: 700;
	margin: 0;
}

.identity > h2 {
	font-weight: 100;
	font-size: 1.1em;
	letter-spacing: 3px;
	margin: 0;
}

.identity > img {
	border-radius: 50%;
	border-color:#fff;
	border: 2px solid;
	max-width: 50vw;
	width: 250px;
	margin: 5px 0;
}
.social-bar {
	display: flex;
	justify-content: space-evenly;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	padding: 2em;
	width: 300px;
}
.icon {
  text-decoration: none;
	color: #fff;
	font-size: 2em;
}

.icon:hover {
  transition: color 0.5s ease;
}

.icon.linkedin:hover {
	color: #0077B5;
}

.icon.instagram:hover {
	color: #fbad50;
}

.icon.facebook:hover {
	color: #3B5998;
}

.icon.email:hover {
	color: #ff6961;
}

.icon.resume:hover {
	color: #77dd77;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

